body {
    margin: 0;
    padding: 0;
}

.dream-header {
    text-align: center;
    display: flex; /* This makes the header a flex container */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    padding: 10px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Pacifico', cursive;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    color: #D1C1E8;
    background: linear-gradient(45deg, #D1C1E8, #F1C2E7);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 1.5em;
    border: 1px solid #D1C1E8; 
    border-radius: 10px;
}

.main-container {
    margin-top: 30px;
}
  
.content-container {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
  
.content-container h2 {
    text-align: justify;
    margin: 10px 0;
}
  
.content-container p {
    text-align: justify;
}

.search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
}

.input-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
  
.search-container input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}
  
.search-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007BFF;
    color: #fff;
    cursor: pointer;
}

.error-message {
    color: red;
    text-align: center;
    margin-top: 20px;
}

.youtube-player {
    margin-top: 50px;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
}

.img {
    max-width: 50%;
    max-height: 600px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
}

.message-container {
    display: flex;               /* Enables flexbox */
    align-items: center;         /* Vertically centers content */
    justify-content: center;     /* Horizontally centers content */
    width: 100%;
    max-width: 600px;
    text-align: justify;         /* Justifies the text */
    color: green;
    margin: 20px 0;
}

.contents-container {
    display: flex;               /* Enables flexbox */
    align-items: center;         /* Vertically centers content */
    justify-content: center;     /* Horizontally centers content */
    width: 100%;
    max-width: 600px;
    text-align: justify;         /* Justifies the text */
    color: gray;
    margin: 20px 0;
}

.navigation-icon {
    cursor: pointer;
    margin: 0 10px;
    flex-shrink: 0;
}

.active-icon {
    color: #007BFF;
}

.inactive-icon {
    color: gray;
    pointer-events: none;
}

@keyframes dreamyBackground {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

body {
    background: linear-gradient(45deg, #ef77a3, #fda085, #81ecec, #74b9ff);
    background-size: 400% 400%;
    animation: dreamyBackground 20s ease infinite;
}

.dream-container {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    /* border-radius: 10px; */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}