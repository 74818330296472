.header {
    background-color: transparent;
    color: #fff;
    display: flex;           /* Use flex layout */
    width: 50%;              /* Set width to 50% of viewport */
    margin: 50px auto;          /* Center the header */
    justify-content: space-between; /* Space items apart */
    align-items: center;     /* Align items vertically centered */
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.logo {
    font-family: 'Lobster', cursive;  /* Use the Lobster font */
    font-size: 32px;       /* Adjust size as needed */
    font-weight: 300;
    color: #ffffff;        /* White color, adjust if needed */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  /* Text shadow for depth */
    /* Additional styling */
    margin-right: auto;    /* Push to the left in flex container */
}


.home-page {
    position: relative;
    overflow: hidden; /* Disable scrolling */
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    z-index: 1; /* Ensure the home page content is above background video */
}

.background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1; /* Ensure the video is behind all other content */
    transform: translate(-50%, -50%);
    object-fit: cover; /* This ensures the video covers the entire container maintaining its aspect ratio */
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
}

/* For Memory boat animation */
/* @keyframes floatMemory {
    0% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0); }
} */

/* Keyframe for rocking (tilting) */
/* @keyframes rockMemory {
    0% { transform: rotate(-2deg); }
    50% { transform: rotate(2deg); }
    100% { transform: rotate(-2deg); }
} */

.memory-container, .vron-container {
    position: absolute;
    z-index: 2; /* Ensure both containers are above the background video */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.memory-container {
    position: absolute;
    top: 67%;
    left: 55%;
    transform: translate(-50%, -50%); /* Adjusted to center the boat horizontally */
    animation: floatMemory 5s ease-in-out infinite, rockMemory 3s ease-in-out infinite;
}

.memory-text {
    white-space: nowrap;   /* Prevent wrapping */
    font-family: 'Lobster', cursive;  /* Use the Lobster font */
    font-weight: 300;                 /* Lighter font weight */
    font-size: medium;
    letter-spacing: 1px;
    color: white;          /* Font color */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);  /* Text shadow for better visibility */
}

.memory-image {
    cursor: pointer;
    width: 15px;   /* Adjusted size for better visibility */
    height: auto;   /* Maintains aspect ratio */
    padding-top: 10px;
}

/* For VRon boat animation */
/* @keyframes floatVRon {
    0% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0); }
} */

/* Keyframe for rocking (tilting) */
/* @keyframes rockVRon {
    0% { transform: rotate(-2deg); }
    50% { transform: rotate(2deg); }
    100% { transform: rotate(-2deg); }
} */

.vron-container {
    position: absolute;
    top: 65.5%; /* Match the vertical positioning with the memory boat */
    left: 66%; /* Adjust left percentage for better spacing */
    transform: translateY(-50%);
    animation: floatVRon 5s ease-in-out infinite, rockVRon 3s ease-in-out infinite;
}

.vron-text {
    white-space: nowrap;   /* Prevent wrapping */
    font-family: 'Lobster', cursive;  /* Use the Lobster font */
    font-weight: 300;                 /* Lighter font weight */
    font-size: medium;
    letter-spacing: 1px;
    color: white;          /* Font color */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);  /* Text shadow for better visibility */
}

.vron-image {
    cursor: pointer;
    width: 10px;   /* Match the size with the memory boat */
    height: auto;   /* Maintains aspect ratio */
    padding-top: 10px;
}

.card-button {
    margin-top: 20px;
    padding: 15px;
    background-color: #4CAF50;
    color: white;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #45a049;
    }
}

button.custom-button {
    font-family: 'Lobster', cursive;  /* Use the Lobster font */
    font-weight: 300;                 /* Lighter font weight */
    font-size: medium;
    letter-spacing: 1px;
    background-color: skyblue;        /* Skyblue background color */
    color: #ffffff;                   /* White text color */
    padding: 8px 30px;                /* Padding for aesthetics */
    border: none;                     /* Remove default border */
    border-radius: 4px;               /* Rounded corners */
    cursor: pointer;                  /* Change cursor on hover */
    transition: background-color 0.3s ease; /* Transition for hover effect */
    outline: none;                    /* Remove focus outline */
    margin-left: 20px;
}

button.custom-button:hover {
    background-color: deepskyblue;    /* Darker shade on hover */
}

.sound-button {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);   /* A subtle shadow for recognition */
}




@keyframes float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

.no-select {
    user-select: none;
}

.no-pointer-events {
    pointer-events: none;
}

