/* Main container styles */
.infiniteWorldContainer {
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: radial-gradient(circle, #1a1a2e 20%, #2e323b 80%);
    color: #eaeaea;
  }
  
  /* Header styles */
  .infiniteWorldHeader {
    font-size: 1.2rem;
    color: #e48494;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  
  /* Main content styles */
  .infiniteWorldMain {
    max-width: 800px;
    padding: 0 20px;
  }

  .infiniteWorldMain img {
    width: 600px;

  }
  
  /* Typing text styles */
  .typingText {
    font-size: 1.0rem;
    line-height: 1.6;
    white-space: pre-wrap;
    text-align: justify;
    border-left: 4px solid #e61437;
    padding-left: 15px;
    /* animation: caret-blink 1.5s step-end infinite; */
    min-height: 200px;
  }

  .nextButton {
    background-color: #419243; /* Green background */
    border: none;              /* No border */
    color: white;               /* White text */
    padding: 10px 24px;         /* Padding inside the button */
    text-align: center;         /* Center text */
    text-decoration: none;      /* No underline */
    display: inline-block;      /* Display inline */
    font-size: 16px;            /* Font size */
    margin: 20px 2px;            /* Some margin */
    cursor: pointer;            /* Pointer cursor on hover */
    border-radius: 4px;        /* Rounded corners */
    transition-duration: 0.2s;  /* Smooth transition effect */
  }
  
  .nextButton:hover {
    background-color: #d2ebd2;    /* White background on hover */
    color: rgb(96, 96, 96);               /* Black text on hover */
    border: 2px solid #419243;  /* Add green border on hover */
  }
  

  /* Caret animation */
  @keyframes caret-blink {
    50% {
      border-color: transparent;
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .infiniteWorldHeader {
      font-size: 1rem;
    }
  
    .typingText {
      font-size: 1rem;
    }
  }
  